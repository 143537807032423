import { useEffect } from 'react'
import { useShippingForm, useUser } from 'modules/user'


/**
 * Creates shipping form with values from primary shipping address and sync on change
 */
const useShippingFormWithPrimaryAddress = () => {
  const { user, country: defaultCountry } = useUser()
  const primaryAddress = user.addressInfo?.shipping
  const shippingForm = useShippingForm({ initialValues: primaryAddress, defaultCountry, useLocalStorage: false })

  useEffect(() => {
    // update on change
    if (primaryAddress) {
      shippingForm.setValues(primaryAddress)
    }
  }, [ primaryAddress, shippingForm ])

  return shippingForm
}

export default useShippingFormWithPrimaryAddress
