import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type AddressDetailsQueryVariables = Types.Exact<{
  input: Types.AddressAutocompleteDetailsInput;
}>;


type AddressDetailsQueryPayload = { __typename?: 'Query', addressAutocompleteDetails: { __typename?: 'AddressAutocompleteDetailsPayload', data: { __typename?: 'Address', city: string | null, region: string | null, postalCode: string | null, street1: string | null, street2: string | null } | null } };


 const AddressDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AddressDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddressAutocompleteDetailsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addressAutocompleteDetails"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"region"}},{"kind":"Field","name":{"kind":"Name","value":"postalCode"}},{"kind":"Field","name":{"kind":"Name","value":"street1"}},{"kind":"Field","name":{"kind":"Name","value":"street2"}}]}}]}}]}}]} as unknown as DocumentNode<AddressDetailsQueryPayload, AddressDetailsQueryVariables>;


export type AddressDetailsVariables = AddressDetailsQueryVariables;
export type AddressDetailsPayload = AddressDetailsQueryPayload;

// remove fragments duplicates
// @ts-expect-error
AddressDetailsDocument['definitions'] = [ ...new Set(AddressDetailsDocument.definitions) ];

export default AddressDetailsDocument;