import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type ShippingAddressesQueryVariables = Types.Exact<{ [key: string]: never; }>;


type ShippingAddressesQueryPayload = { __typename?: 'Query', currentUser: { __typename?: 'UserPayload', data: { __typename?: 'UserData', shippingAddresses: Array<{ __typename?: 'Address', id: number | null, primary: boolean | null, firstName: string | null, lastName: string | null, country: string | null, city: string | null, region: string | null, postalCode: string | null, street1: string | null, street2: string | null, phone: string | null }> } | null } };


 const ShippingAddressesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ShippingAddresses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shippingAddresses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Address"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Address"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Address"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"primary"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"country"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"region"}},{"kind":"Field","name":{"kind":"Name","value":"postalCode"}},{"kind":"Field","name":{"kind":"Name","value":"street1"}},{"kind":"Field","name":{"kind":"Name","value":"street2"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}}]}}]} as unknown as DocumentNode<ShippingAddressesQueryPayload, ShippingAddressesQueryVariables>;


export type ShippingAddressesVariables = ShippingAddressesQueryVariables;
export type ShippingAddressesPayload = ShippingAddressesQueryPayload;

// remove fragments duplicates
// @ts-expect-error
ShippingAddressesDocument['definitions'] = [ ...new Set(ShippingAddressesDocument.definitions) ];

export default ShippingAddressesDocument;