import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type AddressAutocompleteQueryVariables = Types.Exact<{
  input: Types.AddressAutocompleteInput;
}>;


type AddressAutocompleteQueryPayload = { __typename?: 'Query', addressAutocomplete: { __typename?: 'AddressAutocompletePayload', data: Array<{ __typename?: 'AddressSuggestion', type: Types.AddressSuggestionType | null, placeId: string, mainText: string, highlight: string | null, secondaryText: string }> | null } };


 const AddressAutocompleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AddressAutocomplete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddressAutocompleteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addressAutocomplete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"placeId"}},{"kind":"Field","name":{"kind":"Name","value":"mainText"}},{"kind":"Field","name":{"kind":"Name","value":"highlight"}},{"kind":"Field","name":{"kind":"Name","value":"secondaryText"}}]}}]}}]}}]} as unknown as DocumentNode<AddressAutocompleteQueryPayload, AddressAutocompleteQueryVariables>;


export type AddressAutocompleteVariables = AddressAutocompleteQueryVariables;
export type AddressAutocompletePayload = AddressAutocompleteQueryPayload;

// remove fragments duplicates
// @ts-expect-error
AddressAutocompleteDocument['definitions'] = [ ...new Set(AddressAutocompleteDocument.definitions) ];

export default AddressAutocompleteDocument;