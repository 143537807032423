import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type CountryListQueryVariables = Types.Exact<{
  withRegions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


type CountryListQueryPayload = { __typename?: 'Query', countryList: { __typename?: 'CountryListPayload', data: Array<{ __typename?: 'Country', id: number, alpha2: string, name: string, shippingPrice: number, regions?: Array<{ __typename?: 'Region', id: number, name: string, alpha2: string }> }> } };


 const CountryListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CountryList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"withRegions"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}},"defaultValue":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countryList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"alpha2"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shippingPrice"}},{"kind":"Field","name":{"kind":"Name","value":"regions"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"include"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"if"},"value":{"kind":"Variable","name":{"kind":"Name","value":"withRegions"}}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"alpha2"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CountryListQueryPayload, CountryListQueryVariables>;


export type CountryListVariables = CountryListQueryVariables;
export type CountryListPayload = CountryListQueryPayload;

// remove fragments duplicates
// @ts-expect-error
CountryListDocument['definitions'] = [ ...new Set(CountryListDocument.definitions) ];

export default CountryListDocument;